<template>

    <div class="tag-input">
        <!-- INPUT + CHOSEN TAGS -->
        <input 
            enterkeyhint="go"
            class="input is-rounded"
            :style="{ 'padding-left': `${paddingLeft}px` }"
            :placeholder="placeholder"
            :type="inputtype || 'text'"
            :step="inputstep || 'any'"
            :min="inputmin"
            ref="newTagInput"
            @input="inputHandler"
            @keydown.enter.prevent="handleInputEnter(newTag)" 
            @keydown.delete="newTag.length || $emit('removeTag',tags.length - 1)"
        />
        <div class="tags-taginput-container" ref="tagslist">
            <div v-for="tag in tags" :key="tag"  class="has-addons is-flex-direction-row">
                <Pill class="m-1" v-if="tag && tagIsNotFilteredOut(tag)" :tag="tag+''" @onDeleteClick="$emit('removeTag',tag)"/>
            </div>
        </div>

        <!-- Autocomplete / Typeahead -->
        <div v-show="searchResults.length">
            <div class="tags-taginput-autocomplete">
                <div v-for="tag in searchResults" :key="tag"  class="has-addons m-1 is-flex-direction-row">
                    <Pill :tag="tag" hideDeleteButton="true" @click="addTag(tag)"/>
                </div>
            </div>
        </div>
    
    </div>

</template>

<script>
import Pill from '@/components/Template/Pill.vue'

import {ref,watch,nextTick,onMounted,computed} from 'vue'

export default {
    props:{
        modelValue: { type: Array, default: ()=> [] },
        searchResults : {type: Array, default: ()=> []},
        placeholder: { type: String },
        filterDisplayedTags: {type: Function},
        limit: {type: String},
        inputtype: {type: String},
        inputstep: {type: String},
        inputmin: {type: String},
    },
    emits: ["addTag","removeTag","update:modelValue","tagInputUpdate"],
    components:{Pill},
    setup(props,{emit}){
        const directTagAddingAllowed = ref(process.env.VUE_APP_DIRECT_TAG_ADDING_ALLOWED == 'true');
        const tagslist = ref(null)
        const paddingLeft = ref(10);
        const tags = ref(props.modelValue);
        const newTag = ref('') // keep up with new tag
        const newTagInput = ref(null); // keep up with input field
        
        watch(computed(()=>props.modelValue),()=>{
            tags.value = props.modelValue;
        })

        const handleInputEnter = (tag) => {
            if (!directTagAddingAllowed.value) {
                console.warn("direct adding of tags is not allowed. click on autocomplete",directTagAddingAllowed.value);
                return
            } 
            addTag(tag);
        }

        const addTag = (tag) => {
            console.log("tag",tag);
            tag = tag.trim();
            if (!tag || tag.length < 1 ) { // if shorter than 1 characters or only whitespaces
                console.warn("not tag value or only spaces in tag value.");
                return
            }
            emit("addTag",tag);
            if (tags.value && !tags.value.includes(tag)){
                if(props.limit && tags.value.length > Number.parseInt(props.limit)){
                    console.warn("cannot add more than ",props.limit)
                }
                tags.value.push(tag); // add the new tag to the tags array
            }
            newTag.value = ""; // reset newTag
            newTagInput.value.value = ""//reset tag input value
            console.log("reset newTag");
        };

        const onTagsChange = () => {
            const extraCushion = 15
            paddingLeft.value = tagslist.value.clientWidth + extraCushion;
            tagslist.value.scrollTo(tagslist.value.scrollWidth, 0);
            emit("update:modelValue", tags.value)
        }

        watch(tags, ()=> nextTick(onTagsChange), {deep: true});
        
        // this is the workaround to make it work on android soft keyboards (see: https://forum.vuejs.org/t/weird-watcher-behaviour-on-android-browsers/123531/2)
        // TODO: check if we could/should get rid of v-model altogether here.
        const lastCharTyped = ref(null);
        const inputHandler = (e) => {
            console.log("inputhandler triggered",e.target.value);
            lastCharTyped.value = e.data;
        if (e.target.value) { //TODO: filter better when to do this
            newTag.value = e.target.value;
            console.log("inputhandler emitting", newTag.value);
            emit("tagInputUpdate", newTag.value), { immediate: true };
        }
        };

        onMounted(onTagsChange);
    
        function tagIsNotFilteredOut(tag) {
        
         if(props.filterDisplayedTags){
             return props.filterDisplayedTags({tag, tags, newTag})
         }
         else{
             return true
         }
        
        }

        return { tags, newTag , addTag, paddingLeft, tagslist,tagIsNotFilteredOut, inputHandler, newTagInput,handleInputEnter}
    }
}
</script>

<style scoped>
input{
    width: 100%;
}
.input:invalid {
    border-color: #f14668; /* -> is-danger */
}
.tag-input {
  position: relative;
  width: 100%;
  height: 100%;
}
.tags-taginput-container{
    display: flex;
    position: absolute;
    top: 1.7em;
    bottom: -0.2em;
    left: 10px;
    flex-direction: row;
    overflow-y: hidden;
    max-width: 80%;
    height: 2.5em;
}

.tags-taginput-autocomplete{
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    background-color: gainsboro;
}

</style>